<template>
    <div class="ReportEntityDetails__Component">
      <FieldInlineText
        label="סטטוס"
        @onChange="onChange"
        :readOnly="true"
        v-model="model.Status"/>

      <FieldInlineText
        label="שם לקוח"
        @onChange="onChange"
        :readOnly="true"
        v-model="model.ProfileName"/>

      <FieldInlineText
        label="אסמכתה (ירושלים)"
        @onChange="onChange"
        :readOnly="true"
        v-model="model.MuniReferenceNumber"/>

      <FieldInlineText
        label="סכום מקורי"
        @onChange="onChange"
        required="true"
        :readOnly="readOnly"
        v-model="model.OriginalAmount"/>

      <FieldInlineText
        label="סכום ששולם"
        @onChange="onChange"
        :readOnly="true"
        v-model="model.PartialPaidAmount"/>

      <FieldInlineText
        label="בעל הרכב"
        @onChange="onChange"
        :readOnly="true"
        v-model="model.Owner"/>

      <FieldInlineText
        label="היטל נהג"
        @onChange="onChange"
        :readOnly="true"
        v-model="model.DriverAmount"/>

      <FieldInlineText
        label="יתרה לתשלום"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.CurrentAmount"/>

      <FieldInlineDateTime
        label="תאריך יצירה"
        @onChange="onChange"
        :readOnly="true"
        v-model="model.CreateDate" />

      <FieldInlineDate
        label="תאריך אחרון לתשלום"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.LastPayDate" />

      <FieldInlineText
        label="כתובת"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.Address"/>

      <FieldInlineTextArea
        label="תיאור המקרה"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.Reason"/>

      <FieldInlineText
        label="תקנה"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.RelevantLaw"/>

      <FieldInlineText
        label="מזהה עיריה"
        @onChange="onChange"
        :read-only="readOnly"
        v-model="model.MuniIdNumber"/>

      <FieldInlineDate
        label="תאריך אימות"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.VerificationDate" />

      <iframe
        v-if="model.AddressLat && model.AddressLon"
        :src="`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.${model.AddressLon}!2d${AddressLat}!3d32.05980746277334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4b06457c8b43%3A0xc2a768266f1f7c72!2z15PXqNeaINeU16nXnNeV150gMTMxLCDXqtecINeQ15HXmdeRINeZ16TXlQ!5e0!3m2!1siw!2sil!4v1614160420394!5m2!1siw!2sil`"
        width="100%"
        height="100"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ReportEntityDetails',
  components: {
    FieldInlineText,
    FieldInlineDate,
    FieldInlineDateTime,
    FieldInlineTextArea,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        VerificationDate: null,
        MuniIdNumber: null,
        LastPayDate: null,
        OriginalAmount: null,
        CurrentAmount: null,
        ProfileName: null,
        Reason: null,
        RelevantLaw: null,
        Address: null,
        Owner: null,
        CreateDate: null,
        Status: null,
        PartialPaidAmount: null,
        DriverAmount: null,
        MuniReferenceNumber: null,
      },
    };
  },
  computed: {
    ...mapGetters('report', [
      'form',
    ]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
            if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeReportDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('report', [
      'storeReportDetails',
    ]),
  },
};
</script>

<style>

</style>
